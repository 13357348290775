import React from 'react'

const YoutubeEmbed = ({ src, title, width = 560, height = 315 }) => {
  return (
    <iframe
      width={`${width}`}
      height={`${height}`}
      src={src}
      title={title}
      frameBorder='0'
      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
      allowFullScreen
    ></iframe>
  )
}

export default YoutubeEmbed
