import axios from 'axios'
import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'

const useRequest = (key, type = 'post') => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [data, setData] = useState()
  const accessToken = useSelector((state) => state.session.accessToken)
  const send = useCallback(
    async (payload) => {
      setLoading(true)
      try {
        const res = await axios[type](`${process.env.REACT_APP_API_SERVER_URL}${key}`, payload, {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        setData(res.data)
        setError(null)
      } catch (error) {
        console.log('Error: useRequest: ', error)
        setData()
        setError(error)
      }
      setLoading(false)
    },
    [accessToken, key, type]
  )

  return { loading, error, data, send }
}

export default useRequest
