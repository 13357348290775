import React from 'react'

const Logo = () => {
  return (
    <div className='font-logo text-4xl'>
      <div className='relative top-3'>Night</div>
      <div className='text-blue-400'>Moves</div>
    </div>
  )
}

export default Logo
