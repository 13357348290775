import React, { useCallback, useEffect, useRef, useState } from 'react'
import useFormState from '@akshay-nm/use-form-state'
import useRequest from '../hooks/useRequest'
import { useDispatch } from 'react-redux'
import {
  addErrorNotification,
  addSimpleNotification,
  addSuccessNotification,
  addWarningNotification,
} from '../app/notificationsSlice'
import ReCAPTCHA from 'react-google-recaptcha'
import TimedOutButton from './TimedOutButton'
import useInterval from '../hooks/useInterval'
import { AnimatePresence, motion } from 'framer-motion'

const BuzzMe = () => {
  const { isValid, isValidating, name, onNameChange, message, onMessageChange } = useFormState({
    states: [
      { name: 'message', default: '', defaultIsValid: false, mustBeValid: true, validator: (val) => val.length > 0 },
      { name: 'name', default: '', defaultIsValid: false, mustBeValid: true, validator: (val) => val.length > 0 },
    ],
  })
  const { loading: isLocked, error, data, send } = useRequest('/buzz', 'post')

  const [loadingRecaptchaToken, setLoadingRecaptchaToken] = useState(false)
  const [timeoutCounter, setTimeoutCounter] = useState(0)
  const [timeoutDelay, setTimeoutDelay] = useState(0)

  useInterval(() => {
    setTimeoutCounter((prev) => prev - 1)
  }, timeoutDelay)

  useEffect(() => {
    if (timeoutCounter === 0) {
      setTimeoutDelay(0)
    }
  }, [timeoutCounter])

  const recaptchaRef = useRef()

  const dispatch = useDispatch()

  const sendBuzz = useCallback(async () => {
    if (isLocked) dispatch(addWarningNotification('Please wait, waiting for response from NM services.'))
    else if (isValidating) dispatch(addWarningNotification('Please wait, validating the form.'))
    else if (loadingRecaptchaToken)
      dispatch(addWarningNotification('Please wait, I need to confirm that you are a hooman first.'))
    else if (timeoutDelay !== 0) dispatch(addWarningNotification('Hold your horses!'))
    else if (isValid) {
      setLoadingRecaptchaToken(true)
      try {
        dispatch(addWarningNotification('Verifying that you are a hooman...'))
        const token = await recaptchaRef.current.executeAsync()
        dispatch(addWarningNotification('Got your report card, buzzing now...'))
        send({ name, message, token })
        dispatch(addWarningNotification('Sent buzz...'))
        recaptchaRef.current.reset()
        dispatch(addSimpleNotification('Recaptcha reset.'))
      } catch (error) {
        console.log('Recaptcha error: ', error)
        dispatch(addErrorNotification('What are you... if not hooman?'))
      }
      setLoadingRecaptchaToken(false)
    } else dispatch(addErrorNotification('Please fill the form correctly.'))
  }, [loadingRecaptchaToken, isLocked, isValidating, isValid, name, message, timeoutDelay, send, dispatch])

  useEffect(() => {
    if (!isLocked && data) {
      dispatch(addSuccessNotification('Buzzzzzzzzed'))
      setTimeoutCounter(5)
      setTimeoutDelay(1000)
    }
  }, [isLocked, data, dispatch])

  useEffect(() => {
    if (error) dispatch(addErrorNotification('Could not send Buzz...'))
  }, [error, dispatch])

  return (
    <div className='p-4'>
      <div className='mb-4'>
        <div>Name</div>
        <input
          className='w-full border rounded-lg border-blue-400 p-4'
          value={name}
          onChange={(event) => onNameChange(event.target.value)}
        />
      </div>
      <div className='mb-4'>
        <div>Message</div>
        <textarea
          className='w-full border rounded-lg border-blue-400 p-4'
          value={message}
          onChange={(event) => onMessageChange(event.target.value)}
        />
      </div>
      <div>
        <AnimatePresence>
          {timeoutDelay === 0 && (
            <motion.button
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={sendBuzz}
              className={`px-2 py-1 border rounded ${isValidating || isLocked ? '' : 'border-blue-400'}`}
            >
              Bzzzzzzzz
            </motion.button>
          )}
        </AnimatePresence>

        <TimedOutButton isVisible={timeoutDelay !== 0} remaining={timeoutCounter} />
      </div>
      <ReCAPTCHA ref={recaptchaRef} sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} size='invisible' />
    </div>
  )
}

export default BuzzMe
