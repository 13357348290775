import { AnimatePresence, motion } from 'framer-motion'
import React, { useCallback, useEffect, useState } from 'react'
import { useRef } from 'react'
import useSWR from 'swr'
import BuzzMe from './BuzzMe'
import YoutubeEmbed from './YoutubeEmbed'

const ratio = 560 / 315

const AmILive = () => {
  const { isValidating: loading, data, error } = useSWR('/polls/am-i-live')
  const livestreamContainerRef = useRef(null)

  const [streamWindowSize, setStreamWindowSize] = useState({
    width: 560,
    height: 315,
  })

  const calculateStreamWindowSize = useCallback(() => {
    if (livestreamContainerRef.current) {
      const newWidth = livestreamContainerRef.current.offsetWidth
      const newHeight = newWidth / ratio
      setStreamWindowSize({ width: newWidth, height: newHeight })
    }
  }, [])

  useEffect(() => {
    const listener = window.addEventListener('resize', calculateStreamWindowSize)
    return () => window.removeEventListener('resize', listener)
  }, [calculateStreamWindowSize])

  return (
    <AnimatePresence>
      {!data && error && (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} key='fail'>
          Could not confirm live stream status.
        </motion.div>
      )}
      {!data && loading && (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} key='loading'>
          Loading
        </motion.div>
      )}
      {data && (
        <>
          {data.isLive ? (
            <motion.div key='live' className='grid grid-cols-1 md:grid-cols-2'>
              <div className='flex items-center justify-center'>
                <div className='border rounded-lg border-blue-400 mb-4 overflow-hidden' ref={livestreamContainerRef}>
                  <YoutubeEmbed
                    src={data.streamSRC}
                    title={data.streamTitle}
                    width={streamWindowSize.width}
                    height={streamWindowSize.height}
                  />
                </div>
              </div>
              <div className='flex items-center justify-center'>
                <div className='border rounded-lg'>
                  <BuzzMe />
                </div>
              </div>
            </motion.div>
          ) : (
            <motion.div key='not_live' className='flex items-center justify-center p-10'>
              <div className='border rounded-lg p-10'>NM is not live at the moment.</div>
            </motion.div>
          )}
        </>
      )}
    </AnimatePresence>
  )
}

export default AmILive
