import { createSlice } from '@reduxjs/toolkit'
import jwt_decode from 'jwt-decode'

const initialState = {
  userIsLoggedIn: false,
  accessToken: null,
  refreshToken: null,
  user: null,
  sessionIsBeingRefreshed: false,
}

const slice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    updateUserIsLoggedIn: (state, action) => {
      state.userIsLoggedIn = action.payload
    },
    updateAccessToken: (state, action) => {
      state.accessToken = action.payload
    },
    updateRefreshToken: (state, action) => {
      state.refreshToken = action.payload
    },
    updateUser: (state, action) => {
      state.user = action.payload
    },
    updateSessionIsBeingRefreshed: (state, action) => {
      state.sessionIsBeingRefreshed = action.payload
    },
  },
})

export const {
  updateUserIsLoggedIn,
  updateAccessToken,
  updateRefreshToken,
  updateUser,
  updateSessionIsBeingRefreshed,
} = slice.actions

export const logUserIn = ({ accessToken, refreshToken }) => (dispatch) => {
  const { name, id, email, type } = jwt_decode(accessToken)
  dispatch(updateAccessToken(accessToken))
  dispatch(updateRefreshToken(refreshToken))
  dispatch(updateUser({ id, name, email, type }))
  dispatch(updateUserIsLoggedIn(true))
}

export const logUserOut = () => (dispatch) => {
  dispatch(updateAccessToken(initialState.accessToken))
  dispatch(updateRefreshToken(initialState.refreshToken))
  dispatch(updateUser(initialState.user))
  dispatch(updateUserIsLoggedIn(initialState.userIsLoggedIn))
  dispatch(updateSessionIsBeingRefreshed(initialState.sessionIsBeingRefreshed))
}

export const refreshSession = ({ accessToken, refreshToken }) => (dispatch, getState) => {
  dispatch(updateAccessToken(accessToken))
  dispatch(updateRefreshToken(refreshToken))
  console.log('Session refresh is complete.')
  dispatch(updateSessionIsBeingRefreshed(false))
}

export default slice.reducer
