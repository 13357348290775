import React from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import Lottie from 'react-lottie'
import animationData from './live-indicator.json'
import useSWR from 'swr'

const LiveIndicator = () => {
  const { data } = useSWR('/polls/am-i-live')
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
    <AnimatePresence>
      {data?.isLive && (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className='relative'>
          <div className='absolute -top-36 left-16'>
            <Lottie options={defaultOptions} height={200} width={200} />
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default LiveIndicator
