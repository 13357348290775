import Notifications from './components/notifications/Notifications'

import AmILive from './components/AmILive'
import Logo from './components/Logo'
import YoutubePlaylist from './components/YoutubePlaylist'
import LiveIndicator from './components/LiveIndicator'

function App() {
  return (
    <div className='w-full h-full flex flex-col'>
      <div className='p-4'>
        <Logo />
        <LiveIndicator />
      </div>
      <div className='flex-grow overflow-auto p-4'>
        <AmILive />
        <YoutubePlaylist playlist='Moments' path='/playlists/moments' />
        <YoutubePlaylist playlist='Music' path='/playlists/music' />
      </div>
      <Notifications />
    </div>
  )
}

export default App
