import React from 'react'
import useSWR from 'swr'
import YoutubeEmbed from './YoutubeEmbed'

const YoutubePlaylist = ({ playlist, path }) => {
  const { isValidating: loading, error, data: videos } = useSWR(path)
  return (
    <>
      <div>{playlist}</div>
      {videos && (
        <>
          {videos.map((video) => (
            <YoutubeEmbed key={video.src} src={video.src} title={video.title} />
          ))}
        </>
      )}
      {!videos && loading && <div>Loading</div>}
      {!videos && !loading && error && <div>Could not load</div>}
    </>
  )
}

export default YoutubePlaylist
