import { configureStore } from '@reduxjs/toolkit'

import sessionReducer from './sessionSlice'
import notificationsReducer from './notificationsSlice'

const storeParams = {
  reducer: {
    session: sessionReducer,
    notifications: notificationsReducer,
  },
}

export default configureStore(storeParams)
