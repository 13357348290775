import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { Provider } from 'react-redux'
import store from './app/store'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { SWRConfig } from 'swr'
import axios from 'axios'

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <SWRConfig
        value={{
          refreshInterval: 15000,
          fetcher: async (key) => {
            const response = await axios.get(`${process.env.REACT_APP_API_SERVER_URL}${key}`)

            return response.data
          },
          onError: (error, key) => {
            if (error.response) {
              /*
               * The request was made and the server responded with a
               * status code that falls out of the range of 2xx
               */
              console.log(error.response.data)
              console.log(error.response.status)
              console.log(error.response.headers)
            } else if (error.request) {
              /*
               * The request was made but no response was received, `error.request`
               * is an instance of XMLHttpRequest in the browser and an instance
               * of http.ClientRequest in Node.js
               */
              console.log(error.request)
            } else {
              // Something happened in setting up the request and triggered an Error
              console.log('Error', error.message)
            }
          },
        }}
      >
        <App />
      </SWRConfig>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
