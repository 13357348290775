import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'

const buttonVariants = {
  small: { scale: 0.95 },
  normal: { scale: 1 },
}

const TimedOutButton = ({ isVisible, remaining }) => {
  return (
    <AnimatePresence>
      {isVisible && (
        <motion.button
          className='px-2 py-1 border rounded border-yellow-400 text-yellow-400'
          animate={remaining % 2 === 0 ? 'small' : 'normal'}
          transition={{ duration: 1 }}
          variants={buttonVariants}
        >
          Please wait for {remaining} seconds
        </motion.button>
      )}
    </AnimatePresence>
  )
}

export default TimedOutButton
