import React, { useEffect } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { useDispatch, useSelector } from 'react-redux'
import { removeNotification } from '../../app/notificationsSlice'
import SuccessNotification from './SuccessNotification'
import WarningNotification from './WarningNotification'
import ErrorNotification from './ErrorNotification'
import SimpleNotification from './SimpleNotification'

const Notification = ({ id }) => {
  const notification = useSelector((state) => state.notifications.values[id])
  const dispatch = useDispatch()

  useEffect(() => {
    const timeout = setTimeout(() => dispatch(removeNotification(id)), 3000)
    return () => clearTimeout(timeout)
  }, [id, dispatch])

  return (
    <>
      {notification && (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
          {notification.isSuccess && <SuccessNotification message={notification.message} />}
          {notification.isWarning && <WarningNotification message={notification.message} />}
          {notification.isError && <ErrorNotification message={notification.message} />}
          {!(notification.isSuccess || notification.isWarning || notification.isError) && (
            <SimpleNotification message={notification.message} />
          )}
        </motion.div>
      )}
    </>
  )
}

const NotificationsList = () => {
  const notifications = useSelector((state) => state.notifications.ids)
  return (
    <>
      {notifications.map((id) => (
        <Notification key={id} id={id} />
      ))}
    </>
  )
}

const Notifications = () => {
  return (
    <div className='absolute bottom-0 pointer-events-none h-48 flex flex-col-reverse p-4'>
      <AnimatePresence>
        <NotificationsList />
      </AnimatePresence>
    </div>
  )
}

export default Notifications
